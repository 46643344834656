import { MainLayout } from "~/layouts/MainLayout";
import { ContentList } from "~/components/ContentList";
import { Link } from "remix";
import { MetaHeader } from "~/components/MetaHeader";

export default function Index() {
  return (
    <>
      <MetaHeader title={"福岡カフェ日記 | 福岡最大級のカフェメディア"} description={"福岡のカフェを「目的」「雰囲気」「メニュー」「カテゴリー」から探せる地域密着メディアです。紹介しているお店は250件以上、あなたのカフェ探しを全力でサポートします。"} />
      <MainLayout>
        <div className="keyContent">
          <img src="/images/article/top/keyVisual.png" alt="kv" width="375" height="250"/>
        </div>
        <div className="bannerArea">
          <Link to="/diagnosis">
            <img src="/images/diagnosis/img_20240126_banner.png" alt="mbti診断" width="355" height="90"/>
          </Link>
        </div>
        <ContentList />
      </MainLayout>
    </>
  );
}